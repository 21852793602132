import React, { Fragment, useEffect, useState } from "react";
import {
  BoxTooltipTitle,
  TooltipMobileAction,
} from "../ToolTipsFolder/ToolTips";
import img1 from "../../assits/Perfogram_Logo_TransparentBG.png";
import { ManageDeleteLogo, addLogo } from "../../store/logoSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ToolSvg from "../Svgs/ToolTipSvg";
import { randomPassword } from "../../RandomID";
function BrandLogo() {
  // const [uploadedLogo, setUploadedLogo] = useState([
  //   {
  //     name: "test",
  //     src: img1,
  //   },
  // ]);

  let { logos } = useSelector((state) => state.logos);

  let [active, setActive] = useState(null);
  let [File, setFile] = useState("");

  let dispatch = useDispatch();
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    console.log("file", file);
    if (file && file.type.startsWith("image/")) {
      setFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        dispatch(
          addLogo({
            name: file.name,
            src: e.target.result,
            id: randomPassword(2),
          })
        );
      };
      reader.readAsDataURL(file);
      setFile("");
    } else {
      // Handle invalid file type
      alert("Please select a valid image file (JPEG, PNG, etc.)");
    }
  };

  console.log("logos", logos);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width
  const handleResize = () => {
    setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, 100); // 100ms delay, you can adjust this as needed
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Fragment>
      <h2 className="accordion-header">
        <div className="accordion-button">
          <text className="text-acco">Logo</text>
          {windowWidth  <= 575 ? (
            <TooltipMobileAction
              backgroundColor={"rgba(27, 31, 41)"}
              title="Add a transparent PNG or GIF logo images, and the logo will appear
at the top left corner of the screen"
              placement="top"
            >
              <ToolSvg />
            </TooltipMobileAction>
          ) : (
            <BoxTooltipTitle
              backgroundColor={"rgba(27, 31, 41)"}
              title="Add a transparent PNG or GIF logo images, and the logo will appear
at the top left corner of the screen"
              placement="top"
            >
              <ToolSvg />
            </BoxTooltipTitle>
          )}
        </div>
      </h2>
      <div className="accordion-collapse">
        <div className="accordion-body">
          <div className="box-logo">
            <div className="wraper-logo">
              {logos &&
                logos.map((e, index) => {
                  return (
                    <div className="cover-logo" key={index}>
                      <div
                        className="wraper-two"
                        onClick={() => setActive(active === index ? "" : index)}
                      >
                        {active === index && <div className="layer"></div>}
                        <img src={e.src} alt="..." />

                        <div
                          className="icon-edit"
                          onClick={(z) => {
                            z.preventDefault();
                            z.stopPropagation();
                            console.log("123123123");
                            dispatch(ManageDeleteLogo(e.id));
                          }}
                        >
                          <BoxTooltipTitle placement="top" title="Delete">
                            <div
                              className="edit"
                              style={{ top: "7px", right: "7px" }}
                            >
                              <i
                                className="fa-solid fa-trash"
                                style={{ color: active === index && "#fff" }}
                              />
                            </div>
                          </BoxTooltipTitle>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <label htmlFor="addLogo" className="add-logo">
                <input
                  id="addLogo"
                  type="file"
                  value={File}
                  onChange={handleImageUpload}
                />
                <span className="add-plus">+</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BrandLogo;
