import React, { useEffect, useState } from "react";
import "./SocialMediaTap.scss";
import {
  BoxTooltipTitle,
  TooltipMobileAction,
} from "../ToolTipsFolder/ToolTips";
import imgfacebook from "../../assits/facebook.png";
import imginsta from "../../assits/instagram.png";
import imgYoutube from "../../assits/youtube.png";
import linkedin from "../../assits/linkedin.png";
import twitter from "../../assits/twitter.png";
import tiktok from "../../assits/tiktok.png";
import LandsacpeImg from "../../assits/Landscape.svg";
import PortraitImg from "../../assits/Portait.svg";
import { CloseSide } from "../../store/theme";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  ManageAddSocial,
  ManageDeleteSocial,
  ManageEditSocial,
  ManageReorderSocial,
  toggleSocialStatus,
} from "../../store/socialSlice";
import ToolSvg from "../Svgs/ToolTipSvg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { randomPassword } from "../../RandomID";
import useClickOutside from "../../useClickOutside";

function SocialMediaTap() {
  let { listOfSocial } = useSelector((state) => state.persistData.socials);

  const handleToggleStatus = (item) => {
    dispatch(toggleSocialStatus(item)); // Dispatch action to update Redux store
  };

  let [boxCreate, setBoxCreate] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [openeAspList, setOpeneAspList] = useState(false);
  const [stateEdit, setStateEdit] = useState("");
  let [hiddenBoxCreate, setHiddenBoxCreate] = useState(true);
  let dispatch = useDispatch();

  let domNode = useClickOutside(() => {
    setOpenList(false);
  });
  let domNodeTwo = useClickOutside(() => {
    setOpeneAspList(false);
  });

  const mediaList = [
    {
      id: "1",
      img: imgfacebook,
      name: "FaceBook",
      link: "https://perfogram.com/",
      url: "rtmps://live-api-s.facebook.com:443/rtmp/",
    },
    {
      id: "2",
      img: imginsta,
      name: "Instagram",
      link: "https://perfogram.com/",
      url: "rtmps://edgetee-upload-dub4-1.xx.fbcdn.net:443/rtmp/",
    },
    {
      id: "3",
      img: imgYoutube,
      name: "YouTube",
      link: "https://perfogram.com/",
      url: "rtmp://a.rtmp.youtube.com/live2/",
    },
    {
      id: "4",
      img: linkedin,
      name: "Linkedin",
      link: "https://perfogram.com/",
      url: "rtmp://a.rtmp.youtube.com/live2/",
    },
    {
      id: "5",
      img: twitter,
      name: "Twitter",
      link: "https://perfogram.com/",
      url: "rtmps://edgetee-upload-dub4-1.xx.fbcdn.net:443/rtmp/",
    },
    {
      id: "6",
      img: tiktok,
      name: "Tiktok",
      link: "https://perfogram.com/",
      url: "rtmps://edgetee-upload-dub4-1.xx.fbcdn.net:443/rtmp/",
    },
  ];

  const Aspects = [
    { id: "1", name: "Landsacpe", img: LandsacpeImg },
    { id: "2", name: "Portrait", img: PortraitImg },
  ];

  let [selectedItem, setSelectedItem] = useState("");
  let [selectedItemApec, setSelectedItemApec] = useState("");

  let [listenTitle, setListenTitle] = useState(false);
  let [listenDettn, setlistenDettn] = useState(false);
  let [listenBroad, setlistenBroad] = useState(false);
  let [listenURL, setlistenURL] = useState(false);
  let [listenAspict, setlistenAspict] = useState(false);

  let [inp, setInp] = useState({ id: randomPassword(2) });

  const handleChange = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleAddSocial = (e, type) => {
    e.preventDefault();

    if (!inp.title) {
      setListenTitle(true);
    }
    if (!inp.destinationKey) {
      setlistenDettn(true);
    }
    if (!inp.braodcastKey) {
      setlistenBroad(true);
    }
    if (!inp.url) {
      setlistenURL(true);
    }
    if (!inp.aspectratio) {
      setlistenAspict(true);
    }
    if (type) {
      if (
        inp.url &&
        inp.aspectratio &&
        inp.destinationKey &&
        inp.braodcastKey &&
        inp.title
      ) {
        setBoxCreate(false);
        dispatch(ManageEditSocial({ id: inp.id, values: inp }));
        setInp({ id: randomPassword(2) });
        setStateEdit("");
        setSelectedItem("");
        setSelectedItemApec("");
        setHiddenBoxCreate(true);
      }
    } else {
      if (
        inp.url &&
        inp.aspectratio &&
        inp.braodcastKey &&
        inp.destinationKey &&
        inp.title
      ) {
        dispatch(ManageAddSocial(inp));
        setBoxCreate(false);
        setInp({ id: randomPassword(2) });
        setStateEdit("");
        setSelectedItem("");
        setSelectedItemApec("");
        setHiddenBoxCreate(true);
      }
    }
  };

  let DeleteFn = (ee) => {
    console.log("DeleteFn", ee);
    dispatch(ManageDeleteSocial(ee));
  };

  const handleEdit = (e) => {
    setStateEdit(e.id);
    setBoxCreate(false);
    setSelectedItem("");
    setSelectedItemApec("");
    setHiddenBoxCreate(false);
    console.log("eeee", e);
    setInp(e);
  };
  const ClosehandleEdit = () => {
    setInp({ id: randomPassword(2) });
    setStateEdit("");
    setBoxCreate(false);
    setSelectedItem("");
    setSelectedItemApec("");
    setHiddenBoxCreate(true);
  };

  let onDragStart = (e) => {
    setStateEdit("");
    setHiddenBoxCreate(false);
    setBoxCreate(false);
    // onDragEnd(false);
  };

  const onDragEnd = (result) => {
    setHiddenBoxCreate(true);
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    if (source.index === destination.index) {
      return; // Item dropped in the same position
    }

    const updatedList = reorder(listOfSocial, source.index, destination.index);
    // setData(updatedList);
    dispatch(ManageReorderSocial(updatedList));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  console.log("inp", inp);
  console.log("listOfSocial", listOfSocial);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width
  const handleResize = () => {
    setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, 100); // 100ms delay, you can adjust this as needed
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className="wrapper-side social-top">
        <div className="Details  p-2">
          <div className="header ">
            <text className="text-acco">Social</text>
            {windowWidth  <= 575 ? (
              <TooltipMobileAction
                backgroundColor={"rgba(27, 31, 41)"}
                title="Stream to multiple social media channels/pages simultaneously
                   Configure hear your social media destinations"
                placement="top"
              >
                <ToolSvg />
              </TooltipMobileAction>
            ) : (
              <BoxTooltipTitle
                backgroundColor={"rgba(27, 31, 41)"}
                title="Stream to multiple social media channels/pages simultaneously
                   Configure hear your social media destinations"
                placement="top"
              >
                <ToolSvg />
              </BoxTooltipTitle>
            )}
            <div className="close" onClick={() => dispatch(CloseSide(false))}>
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>

          <div className="BoxViewer">
            <div className="newBoxViwer socialBoxViwer">
              <Droppable droppableId="draggable">
                {(provided, snapshot) => (
                  <ul
                    className="list-unstyled mb-2 mt-4"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {listOfSocial &&
                      listOfSocial.map((e, index) => (
                        <Draggable
                          key={e.id}
                          draggableId={e.id.toString()}
                          index={index}
                          isDragDisabled={stateEdit ? true : false}
                        >
                          {(provided, snapshot) =>
                            stateEdit == e.id ? (
                              <div className="box-add mt-4">
                                <input
                                  type="text"
                                  className="form-control input"
                                  autoFocus
                                  name="title"
                                  value={inp.title}
                                  onChange={(e) => handleChange(e)}
                                  placeholder="Title (for your refernce only)"
                                  onFocus={() => {
                                    setListenTitle(false);
                                  }}
                                  style={{
                                    borderColor: listenTitle
                                      ? "#f3234a"
                                      : "#333",
                                  }}
                                />
                                {listenTitle && (
                                  <p className="ErrorField">
                                    Title field is required
                                  </p>
                                )}
                                <div
                                  className="position-relative dropdown-social"
                                  ref={domNode}
                                >
                                  <div
                                    className="ddd form-control mt-3"
                                    onClick={() => setOpenList(!openList)}
                                  >
                                    <div>
                                      <div
                                        className="d-flex"
                                        style={{
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          gap: "5px",
                                        }}
                                      >
                                        <div className="box-img">
                                          <img
                                            src={
                                              selectedItem
                                                ? selectedItem.img
                                                : e.icon
                                            }
                                            alt="..."
                                          />
                                        </div>
                                        <span>
                                          {selectedItem
                                            ? selectedItem.name
                                            : e.destinationKey}
                                        </span>
                                      </div>
                                    </div>

                                    <i class="fa-solid fa-chevron-down"></i>
                                  </div>
                                  <div
                                    className="list-social "
                                    style={{
                                      height: openList ? "235px" : "0px",
                                      border: openList
                                        ? "1px solid #777"
                                        : "0px solid #777",
                                    }}
                                  >
                                    {mediaList &&
                                      mediaList.map((e, index) => {
                                        return (
                                          <div className="item" key={index}>
                                            <div
                                              className="box-icon"
                                              onClick={(item) => {
                                                setOpenList(false);
                                                setSelectedItem(e);
                                                setInp((prev) => ({
                                                  ...prev,
                                                  url: e.url,
                                                  icon: e.img,
                                                  destinationKey: e.name,
                                                }));
                                              }}
                                            >
                                              <div className="box-img">
                                                <img src={e.img} alt="..." />
                                              </div>
                                              <span>{e.name}</span>
                                            </div>
                                            <div className="how-to">
                                              (
                                              <a
                                                target="_blank"
                                                href={e.link}
                                                rel="noreferrer"
                                              >
                                                how to?
                                                <i class="fa-solid fa-up-right-from-square"></i>
                                              </a>
                                              )
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>

                                <>
                                  <input
                                    type="text"
                                    className="form-control input mt-2"
                                    autoFocus
                                    name="braodcastKey"
                                    value={inp.braodcastKey}
                                    placeholder="Broadcast Key (from your social account)"
                                    onFocus={() => {
                                      setlistenBroad(false);
                                    }}
                                    style={{
                                      borderColor: listenBroad
                                        ? "#f3234a"
                                        : "#333",
                                    }}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  {listenBroad && (
                                    <p className="ErrorField">
                                      BraodcastKey field is required
                                    </p>
                                  )}

                                  <input
                                    type="text"
                                    className="form-control input mt-3"
                                    autoFocus
                                    placeholder="URL"
                                    name="url"
                                    value={inp.url}
                                    onChange={(e) => handleChange(e)}
                                    onFocus={() => {
                                      setlistenURL(false);
                                    }}
                                    style={{
                                      borderColor: listenURL
                                        ? "#f3234a"
                                        : "#333",
                                    }}
                                  />
                                  {listenURL && (
                                    <p className="ErrorField">
                                      URL field is required
                                    </p>
                                  )}

                                  <div
                                    className="position-relative dropdown-social"
                                    ref={domNodeTwo}
                                  >
                                    <div
                                      className="ddd form-control mt-3"
                                      style={{
                                        borderColor: listenAspict
                                          ? "#f3234a"
                                          : "#333",
                                      }}
                                      onClick={() => {
                                        setlistenAspict(false);
                                        setOpeneAspList(!openeAspList);
                                      }}
                                    >
                                      <div>
                                        <div
                                          className="d-flex"
                                          style={{
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <div className="box-img">
                                            <img
                                              src={
                                                selectedItemApec
                                                  ? selectedItemApec.name ===
                                                    "Landsacpe"
                                                    ? LandsacpeImg
                                                    : PortraitImg
                                                  : e.aspectratio ===
                                                    "Landsacpe"
                                                  ? LandsacpeImg
                                                  : PortraitImg
                                              }
                                              alt="..."
                                            />
                                          </div>
                                          <span>
                                            {selectedItemApec
                                              ? selectedItemApec.name
                                              : e.aspectratio}
                                          </span>
                                        </div>
                                      </div>

                                      <i class="fa-solid fa-chevron-down"></i>
                                    </div>
                                    <div
                                      className="list-social "
                                      style={{
                                        height: openeAspList ? "90px" : "0px",
                                        border: openeAspList
                                          ? "1px solid #777"
                                          : "0px solid #777",
                                      }}
                                    >
                                      {Aspects &&
                                        Aspects.map((e, index) => {
                                          return (
                                            <div className="item" key={index}>
                                              <div
                                                className="box-icon"
                                                onClick={(item) => {
                                                  setOpeneAspList(false);
                                                  setSelectedItemApec(e);
                                                  setInp((prev) => ({
                                                    ...prev,

                                                    aspectratio: e.name,
                                                  }));
                                                }}
                                              >
                                                <div className="box-img">
                                                  <img
                                                    src={
                                                      e.name === "Landsacpe"
                                                        ? LandsacpeImg
                                                        : PortraitImg
                                                    }
                                                    alt="..."
                                                  />
                                                </div>
                                                <span>{e.name}</span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>

                                  {listenAspict && (
                                    <p
                                      className="ErrorField"
                                      style={{ marginTop: "-2px" }}
                                    >
                                      Aspect ratio field is required
                                    </p>
                                  )}
                                </>

                                <div className="box-btn">
                                  <button
                                    className="btn add"
                                    onClick={(e) => handleAddSocial(e, "edit")}
                                  >
                                    <text>Edit Destination</text>
                                  </button>
                                  <button
                                    className="btn cancle"
                                    onClick={(e) => {
                                      // setBoxCreate(false);
                                      ClosehandleEdit();
                                    }}
                                  >
                                    <text>Cancel</text>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <li
                                key={index}
                                style={{ borderBottom: "none" }}
                                className={`draggable-item ${
                                  snapshot.isDragging ? "dragging" : ""
                                } `}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="box-social">
                                  <div className="box-title">
                                    <div className="img-icons">
                                      <img src={e.icon} alt="..." />
                                    </div>
                                    <span>{e.title}</span>
                                  </div>
                                  <div className="box-action">
                                    <div
                                      className="wraper-icon"
                                      onClick={() => handleToggleStatus(e)}
                                    >
                                      <i
                                        className={`fa-solid fa-${
                                          e.status ? "play" : "stop"
                                        }`}
                                      ></i>
                                    </div>
                                    <div className="icon-edit">
                                      <BoxTooltipTitle
                                        placement="top"
                                        title="Edit"
                                      >
                                        <div
                                          className="edit"
                                          style={{
                                            margin: "0",
                                            marginRight: "-5px",
                                          }}
                                          onClick={(zz) => {
                                            handleEdit(e);
                                          }}
                                        >
                                          <i className="fa-solid fa-pen" />
                                        </div>
                                      </BoxTooltipTitle>
                                      <BoxTooltipTitle
                                        placement="top"
                                        title="Delete"
                                      >
                                        <div
                                          className="edit"
                                          onClick={(zz) => DeleteFn(e.id)}
                                        >
                                          <i className="fa-solid fa-trash" />
                                        </div>
                                      </BoxTooltipTitle>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          }
                        </Draggable>
                      ))}

                    {boxCreate ? (
                      <div className="box-add mt-4">
                        <input
                          type="text"
                          className="form-control input"
                          autoFocus
                          name="title"
                          onChange={(e) => handleChange(e)}
                          placeholder="Title (for your refernce only)"
                          onFocus={() => {
                            setListenTitle(false);
                          }}
                          style={{
                            borderColor: listenTitle ? "#f3234a" : "#333",
                          }}
                        />
                        {listenTitle && (
                          <p className="ErrorField"> Title field is required</p>
                        )}
                        <div
                          className="position-relative dropdown-social"
                          ref={domNode}
                        >
                          <div
                            className="ddd form-control mt-3"
                            style={{
                              borderColor: listenDettn ? "#f3234a" : "#333",
                            }}
                            onClick={() => {
                              setlistenDettn(false);
                              setOpenList(!openList);
                            }}
                          >
                            <div>
                              {selectedItem ? (
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <div className="box-img">
                                    <img src={selectedItem.img} alt="..." />
                                  </div>
                                  <span>{selectedItem.name}</span>
                                </div>
                              ) : (
                                <span>Destination</span>
                              )}
                            </div>

                            <i class="fa-solid fa-chevron-down"></i>
                          </div>
                          <div
                            className="list-social "
                            style={{
                              height: openList ? "235px" : "0px",
                              border: openList
                                ? "1px solid #777"
                                : "0px solid #777",
                            }}
                          >
                            {mediaList &&
                              mediaList.map((e, index) => {
                                return (
                                  <div className="item" key={index}>
                                    <div
                                      className="box-icon"
                                      onClick={(item) => {
                                        setOpenList(false);
                                        setSelectedItem(e);
                                        setInp((prev) => ({
                                          ...prev,
                                          url: e.url,
                                          icon: e.img,
                                          destinationKey: e.name,
                                        }));
                                      }}
                                    >
                                      <div className="box-img">
                                        <img src={e.img} alt="..." />
                                      </div>
                                      <span>{e.name}</span>
                                    </div>
                                    <div className="how-to">
                                      (
                                      <a
                                        target="_blank"
                                        href={e.link}
                                        rel="noreferrer"
                                      >
                                        how to?
                                        <i class="fa-solid fa-up-right-from-square"></i>
                                      </a>
                                      )
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        {listenDettn && (
                          <p
                            className="ErrorField"
                            style={{ marginTop: "-2px" }}
                          >
                            Destination field is required
                          </p>
                        )}

                        {selectedItem && (
                          <>
                            <input
                              type="text"
                              className="form-control input mt-2"
                              autoFocus
                              name="braodcastKey"
                              placeholder="Broadcast Key (from your social account)"
                              onFocus={() => {
                                setlistenBroad(false);
                              }}
                              style={{
                                borderColor: listenBroad ? "#f3234a" : "#333",
                              }}
                              onChange={(e) => handleChange(e)}
                            />
                            {listenBroad && (
                              <p className="ErrorField">
                                BraodcastKey field is required
                              </p>
                            )}
                            <input
                              type="text"
                              className="form-control input mt-3"
                              autoFocus
                              placeholder="URL"
                              name="url"
                              value={inp.url}
                              onChange={(e) => handleChange(e)}
                              onFocus={() => {
                                setlistenURL(false);
                              }}
                              style={{
                                borderColor: listenURL ? "#f3234a" : "#333",
                              }}
                            />
                            {listenURL && (
                              <p className="ErrorField">
                                URL field is required
                              </p>
                            )}

                            <div
                              className="position-relative dropdown-social"
                              ref={domNodeTwo}
                            >
                              <div
                                className="ddd form-control mt-3"
                                style={{
                                  borderColor: listenAspict
                                    ? "#f3234a"
                                    : "#333",
                                }}
                                onClick={() => {
                                  setlistenAspict(false);
                                  setOpeneAspList(!openeAspList);
                                }}
                              >
                                <div>
                                  {selectedItemApec ? (
                                    <div
                                      className="d-flex"
                                      style={{
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      <div className="box-img">
                                        <img
                                          src={selectedItemApec.img}
                                          alt="..."
                                        />
                                      </div>
                                      <span>{selectedItemApec.name}</span>
                                    </div>
                                  ) : (
                                    <span>Aspect Ratio</span>
                                  )}
                                </div>

                                <i class="fa-solid fa-chevron-down"></i>
                              </div>
                              <div
                                className="list-social "
                                style={{
                                  height: openeAspList ? "90px" : "0px",
                                  border: openeAspList
                                    ? "1px solid #777"
                                    : "0px solid #777",
                                }}
                              >
                                {Aspects &&
                                  Aspects.map((e, index) => {
                                    return (
                                      <div className="item" key={index}>
                                        <div
                                          className="box-icon"
                                          onClick={(item) => {
                                            setOpeneAspList(false);
                                            setSelectedItemApec(e);
                                            setInp((prev) => ({
                                              ...prev,

                                              aspectratio: e.name,
                                            }));
                                          }}
                                        >
                                          <div className="box-img">
                                            <img src={e.img} alt="..." />
                                          </div>
                                          <span>{e.name}</span>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>

                            {listenAspict && (
                              <p
                                className="ErrorField"
                                style={{ marginTop: "-2px" }}
                              >
                                Aspect ratio field is required
                              </p>
                            )}
                          </>
                        )}

                        <div className="box-btn">
                          <button
                            className="btn add"
                            onClick={(e) => handleAddSocial(e)}
                          >
                            <text>Add Destination</text>
                          </button>
                          <button
                            className="btn cancle"
                            onClick={(e) => {
                              setBoxCreate(false);
                              ClosehandleEdit(e);
                            }}
                          >
                            <text>Cancel</text>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="box-create  mb-2"
                        onClick={(e) => {
                          setBoxCreate(true);
                        }}
                      >
                        {hiddenBoxCreate && (
                          <div className="box-add-video">
                            <div className="wrapper">
                              <label htmlFor="upload">
                                <div className="box-plus">
                                  <span>+</span>
                                </div>
                                <div className="title">
                                  <span>Add Destination</span>
                                </div>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </ul>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}

export default SocialMediaTap;
